import React from 'react';

const NftSvg = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
        fill="#29348B"
        fillOpacity="0.32"
      />
      <path
        d="M17.1622 13V12.9H17.0622H15.3907H15.2907V13V23.9899L8.44786 15.6638L8.41785 15.6273H8.3706H7H6.9V15.7273V27.1818V27.2818H7H8.67146H8.77146V27.1818V18.9192L15.6144 27.2453L15.6444 27.2818H15.6916H17.0622H17.1622V27.1818V13ZM25.0168 21.1436V21.0436H24.9168H21.15V14.5236H33H33.1V14.4236V13V12.9H33H19.3785H19.2785V13V27.1818V27.2818H19.3785H21.05H21.15V27.1818V22.6509H24.9168H25.0168V22.5509V21.1436ZM23.1996 18.3008V18.4008H23.2996H27.2111V27.1818V27.2818H27.3111H28.9659H29.0659V27.1818V18.4008H32.9774H33.0774V18.3008V16.8771V16.7771H32.9774H23.2996H23.1996V16.8771V18.3008Z"
        fill="#FBFCFF"
        stroke="#FBFCFF"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default NftSvg;
