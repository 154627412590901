import React from 'react';

const CostSvg = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
        fill="#215AE7"
      />
      <path
        d="M28 21.09V15C28 12.79 24.42 11 20 11C15.58 11 12 12.79 12 15V25C12 27.21 15.59 29 20 29C20.46 29 20.9 29 21.33 28.94C21.12 28.33 21 27.68 21 27V26.95C20.68 27 20.35 27 20 27C16.13 27 14 25.5 14 25V22.77C15.61 23.55 17.72 24 20 24C20.65 24 21.27 23.96 21.88 23.89C22.93 22.16 24.83 21 27 21C27.34 21 27.67 21.04 28 21.09ZM26 20.45C24.7 21.4 22.42 22 20 22C17.58 22 15.3 21.4 14 20.45V17.64C15.47 18.47 17.61 19 20 19C22.39 19 24.53 18.47 26 17.64V20.45ZM20 17C16.13 17 14 15.5 14 15C14 14.5 16.13 13 20 13C23.87 13 26 14.5 26 15C26 15.5 23.87 17 20 17ZM31 26V28H23V26H31Z"
        fill="#FBFCFF"
      />
    </svg>
  );
};

export default CostSvg;
