import React from 'react';

const TotoalUpcomeSvg = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
        fill="#28CCD2"
      />
      <path
        d="M28.86 18.85C28.9461 18.5746 28.9932 18.2885 29 18V14C29 11.15 24.7 9 19 9C13.3 9 9 11.15 9 14V18C9.05512 18.6662 9.281 19.307 9.65584 19.8605C10.0307 20.414 10.5418 20.8615 11.14 21.16C11.0482 21.4306 11.0009 21.7143 11 22V26C11 28.85 15.3 31 21 31C26.7 31 31 28.85 31 26V22C30.9461 21.3347 30.7206 20.6948 30.3455 20.1427C29.9704 19.5906 29.4586 19.1452 28.86 18.85ZM19 23C22.85 23 26.06 22 27.74 20.5C28.54 20.98 29 21.5 29 22C29 23.22 25.88 25 21 25C16.12 25 13.09 23.27 13 22.05C14.9306 22.7091 16.9603 23.0305 19 23ZM19 11C23.88 11 27 12.78 27 14C27 15.22 23.88 17 19 17C14.12 17 11 15.22 11 14C11 12.78 14.12 11 19 11ZM11 17.06C13.4322 18.4436 16.2042 19.1158 19 19C21.7958 19.1158 24.5678 18.4436 27 17.06V18C27 19.22 23.88 21 19 21C14.12 21 11 19.22 11 18V17.06ZM21 29C16.12 29 13 27.22 13 26V25.06C15.4322 26.4436 18.2042 27.1158 21 27C23.7958 27.1158 26.5678 26.4436 29 25.06V26C29 27.22 25.88 29 21 29Z"
        fill="#FBFCFF"
      />
    </svg>
  );
};

export default TotoalUpcomeSvg;
