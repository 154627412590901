import React from 'react';

const CopyRightSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125ZM9 16.2812C5.09766 16.2812 1.96875 13.1523 1.96875 9.25C1.96875 5.38281 5.09766 2.21875 9 2.21875C12.8672 2.21875 16.0312 5.38281 16.0312 9.25C16.0312 13.1523 12.8672 16.2812 9 16.2812ZM12.7617 12.7305C12.832 12.6602 12.8672 12.5547 12.8672 12.4492C12.8672 12.3438 12.832 12.2734 12.7969 12.2031L12.0938 11.2539C11.9531 11.043 11.6719 11.0078 11.4609 11.1836C11.1445 11.4648 10.3008 12.1328 9.17578 12.1328C7.34766 12.1328 6.39844 10.6562 6.39844 9.21484C6.39844 7.63281 7.45312 6.36719 9.14062 6.36719C10.1953 6.36719 11.0039 6.92969 11.3203 7.17578C11.4961 7.31641 11.8125 7.28125 11.918 7.07031L12.5859 6.08594C12.6211 6.01562 12.6562 5.94531 12.6562 5.83984C12.6562 5.73438 12.5859 5.62891 12.5156 5.55859C12.1992 5.24219 11.0391 4.32812 9.07031 4.32812C6.29297 4.32812 4.14844 6.4375 4.14844 9.21484C4.14844 12.0273 6.1875 14.207 9.10547 14.207C11.1445 14.207 12.4102 13.082 12.7617 12.7305Z"
        fill="white"
      />
    </svg>
  );
};

export default CopyRightSvg;
