import React from 'react';

const CustomSettingsSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#29348B"
        fillOpacity="0.32"
      />
      <path
        d="M16 18.625C15.3038 18.625 14.6361 18.3484 14.1438 17.8562C13.6516 17.3639 13.375 16.6962 13.375 16C13.375 15.3038 13.6516 14.6361 14.1438 14.1438C14.6361 13.6516 15.3038 13.375 16 13.375C16.6962 13.375 17.3639 13.6516 17.8562 14.1438C18.3484 14.6361 18.625 15.3038 18.625 16C18.625 16.6962 18.3484 17.3639 17.8562 17.8562C17.3639 18.3484 16.6962 18.625 16 18.625ZM21.5725 16.7275C21.6025 16.4875 21.625 16.2475 21.625 16C21.625 15.7525 21.6025 15.505 21.5725 15.25L23.155 14.0275C23.2975 13.915 23.335 13.7125 23.245 13.5475L21.745 10.9525C21.655 10.7875 21.4525 10.72 21.2875 10.7875L19.42 11.5375C19.03 11.245 18.625 10.99 18.1525 10.8025L17.875 8.815C17.845 8.635 17.6875 8.5 17.5 8.5H14.5C14.3125 8.5 14.155 8.635 14.125 8.815L13.8475 10.8025C13.375 10.99 12.97 11.245 12.58 11.5375L10.7125 10.7875C10.5475 10.72 10.345 10.7875 10.255 10.9525L8.755 13.5475C8.6575 13.7125 8.7025 13.915 8.845 14.0275L10.4275 15.25C10.3975 15.505 10.375 15.7525 10.375 16C10.375 16.2475 10.3975 16.4875 10.4275 16.7275L8.845 17.9725C8.7025 18.085 8.6575 18.2875 8.755 18.4525L10.255 21.0475C10.345 21.2125 10.5475 21.2725 10.7125 21.2125L12.58 20.455C12.97 20.755 13.375 21.01 13.8475 21.1975L14.125 23.185C14.155 23.365 14.3125 23.5 14.5 23.5H17.5C17.6875 23.5 17.845 23.365 17.875 23.185L18.1525 21.1975C18.625 21.0025 19.03 20.755 19.42 20.455L21.2875 21.2125C21.4525 21.2725 21.655 21.2125 21.745 21.0475L23.245 18.4525C23.335 18.2875 23.2975 18.085 23.155 17.9725L21.5725 16.7275Z"
        fill="#FBFCFF"
      />
    </svg>
  );
};

export default CustomSettingsSvg;
