import React from 'react';

const TooltipSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9165 9.7513H14.0832V7.58464H11.9165V9.7513ZM12.9998 21.668C8.22234 21.668 4.33317 17.7788 4.33317 13.0013C4.33317 8.2238 8.22234 4.33464 12.9998 4.33464C17.7773 4.33464 21.6665 8.2238 21.6665 13.0013C21.6665 17.7788 17.7773 21.668 12.9998 21.668ZM12.9998 2.16797C11.5772 2.16797 10.1685 2.44818 8.8541 2.99261C7.53974 3.53703 6.34548 4.33501 5.33951 5.34098C3.30787 7.37262 2.1665 10.1281 2.1665 13.0013C2.1665 15.8745 3.30787 18.63 5.33951 20.6616C6.34548 21.6676 7.53974 22.4656 8.8541 23.01C10.1685 23.5544 11.5772 23.8346 12.9998 23.8346C15.873 23.8346 18.6285 22.6933 20.6602 20.6616C22.6918 18.63 23.8332 15.8745 23.8332 13.0013C23.8332 11.5786 23.553 10.1699 23.0085 8.85556C22.4641 7.5412 21.6661 6.34695 20.6602 5.34098C19.6542 4.33501 18.4599 3.53703 17.1456 2.99261C15.8312 2.44818 14.4225 2.16797 12.9998 2.16797ZM11.9165 18.418H14.0832V11.918H11.9165V18.418Z"
        fill="#1A72FA"
        fillOpacity="0.64"
      />
    </svg>
  );
};

export default TooltipSvg;
