import React from 'react';

const LocationSvg = () => {
  return (
    <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 5.4C9.30512 5.4 10.0773 5.71607 10.6466 6.27868C11.2159 6.84129 11.5357 7.60435 11.5357 8.4C11.5357 8.79397 11.4572 9.18407 11.3046 9.54805C11.1521 9.91203 10.9285 10.2427 10.6466 10.5213C10.3647 10.7999 10.03 11.0209 9.66172 11.1716C9.29341 11.3224 8.89866 11.4 8.5 11.4C7.69488 11.4 6.92273 11.0839 6.35343 10.5213C5.78412 9.95871 5.46429 9.19565 5.46429 8.4C5.46429 7.60435 5.78412 6.84129 6.35343 6.27868C6.92273 5.71607 7.69488 5.4 8.5 5.4ZM8.5 0C10.7543 0 12.9163 0.884997 14.5104 2.4603C16.1045 4.03561 17 6.17218 17 8.4C17 14.7 8.5 24 8.5 24C8.5 24 0 14.7 0 8.4C0 6.17218 0.895533 4.03561 2.48959 2.4603C4.08365 0.884997 6.24566 0 8.5 0ZM8.5 2.4C6.88976 2.4 5.34547 3.03214 4.20685 4.15736C3.06824 5.28258 2.42857 6.8087 2.42857 8.4C2.42857 9.6 2.42857 12 8.5 20.052C14.5714 12 14.5714 9.6 14.5714 8.4C14.5714 6.8087 13.9318 5.28258 12.7931 4.15736C11.6545 3.03214 10.1102 2.4 8.5 2.4Z"
        fill="white"
      />
    </svg>
  );
};

export default LocationSvg;
