import React from 'react';

const ReferralLinkSvg = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
        fill="#18AAFC"
      />
      <path
        d="M11.9 20C11.9 18.29 13.29 16.9 15 16.9H19V15H15C13.6739 15 12.4021 15.5268 11.4645 16.4645C10.5268 17.4021 10 18.6739 10 20C10 21.3261 10.5268 22.5979 11.4645 23.5355C12.4021 24.4732 13.6739 25 15 25H19V23.1H15C13.29 23.1 11.9 21.71 11.9 20ZM16 21H24V19H16V21ZM25 15H21V16.9H25C26.71 16.9 28.1 18.29 28.1 20C28.1 21.71 26.71 23.1 25 23.1H21V25H25C26.3261 25 27.5979 24.4732 28.5355 23.5355C29.4732 22.5979 30 21.3261 30 20C30 18.6739 29.4732 17.4021 28.5355 16.4645C27.5979 15.5268 26.3261 15 25 15Z"
        fill="#FBFCFF"
      />
    </svg>
  );
};

export default ReferralLinkSvg;
