import React from 'react';

const BinanceSvg = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
        fill="#29348B"
        fillOpacity="0.32"
      />
      <path
        d="M15.339 18.085L20 13.424L24.6631 18.087L27.3751 15.375L20 8L12.6272 15.3728L15.339 18.085ZM8 20L10.7121 17.2875L13.424 19.9994L10.7119 22.7115L8 20ZM15.339 21.9153L20 26.576L24.663 21.9131L27.3765 24.6236L27.3751 24.6251L20 32L12.6272 24.6272L12.6233 24.6234L15.339 21.9153ZM26.5759 20.0012L29.288 17.2891L31.9999 20.001L29.2879 22.7131L26.5759 20.0012Z"
        fill="#FBFCFF"
      />
      <path
        d="M22.7505 19.999H22.7517L19.9999 17.2471L17.966 19.2804L17.7323 19.5142L17.2504 19.9962L17.2466 20L17.2504 20.0039L19.9999 22.7538L22.7519 20.0019L22.7532 20.0004L22.7507 19.999"
        fill="#FBFCFF"
      />
    </svg>
  );
};
export default BinanceSvg;
