import React from 'react';

const PhoneSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6667 16.6667C21.0667 16.6667 19.3333 16.4 17.8667 15.8667C17.7333 15.8667 17.6 15.8667 17.4667 15.8667C17.0667 15.8667 16.8 16 16.5333 16.2667L13.6 19.2C9.86667 17.2 6.66667 14.1333 4.8 10.4L7.73333 7.46667C8.13333 7.06667 8.26667 6.53333 8 6.13333C7.6 4.66667 7.33333 2.93333 7.33333 1.33333C7.33333 0.666667 6.66667 0 6 0H1.33333C0.666667 0 0 0.666667 0 1.33333C0 13.8667 10.1333 24 22.6667 24C23.3333 24 24 23.3333 24 22.6667V18C24 17.3333 23.3333 16.6667 22.6667 16.6667ZM2.66667 2.66667H4.66667C4.8 3.86667 5.06667 5.06667 5.33333 6.13333L3.73333 7.73333C3.2 6.13333 2.8 4.4 2.66667 2.66667ZM21.3333 21.3333C19.6 21.2 17.8667 20.8 16.2667 20.2667L17.8667 18.6667C18.9333 18.9333 20.1333 19.2 21.3333 19.2V21.3333Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneSvg;
