import React from 'react';

const MyPromoCodeSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#29348B"
        fillOpacity="0.32"
      />
      <path
        d="M9.925 16C9.925 14.7175 10.9675 13.675 12.25 13.675H15.25V12.25H12.25C11.2554 12.25 10.3016 12.6451 9.59835 13.3483C8.89509 14.0516 8.5 15.0054 8.5 16C8.5 16.9946 8.89509 17.9484 9.59835 18.6517C10.3016 19.3549 11.2554 19.75 12.25 19.75H15.25V18.325H12.25C10.9675 18.325 9.925 17.2825 9.925 16ZM13 16.75H19V15.25H13V16.75ZM19.75 12.25H16.75V13.675H19.75C21.0325 13.675 22.075 14.7175 22.075 16C22.075 17.2825 21.0325 18.325 19.75 18.325H16.75V19.75H19.75C20.7446 19.75 21.6984 19.3549 22.4017 18.6517C23.1049 17.9484 23.5 16.9946 23.5 16C23.5 15.0054 23.1049 14.0516 22.4017 13.3483C21.6984 12.6451 20.7446 12.25 19.75 12.25Z"
        fill="#FBFCFF"
      />
    </svg>
  );
};

export default MyPromoCodeSvg;
